import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {LoginActionEvent, LoginActionType} from 'common-lib';
import {interval, Subject, timer} from 'rxjs';
import {take, takeUntil} from 'rxjs/operators';

@Component({
    selector: 'ip-login-page',
    templateUrl: './login-page.component.html',
    styleUrls: ['./login-page.component.scss']
})
export class LoginPageComponent implements OnInit {

    public loadingPercent: number = 0;

    constructor(private router: Router) {
    }

    public ngOnInit(): void {
    }

    public onLoginAction(event: LoginActionEvent): void {

        console.log(`ACTION ${event.action}`);

        if (event.action === LoginActionType.LoginSuccess) {

            const ngUnsubscribe = new Subject();
            // simulate loading data and redirect to dashboard
            interval(100)
                .pipe(take(101))
                .pipe(takeUntil(ngUnsubscribe))
                .subscribe(p => {
                    this.loadingPercent = p;
                    if (p === 100) {
                        timer(500)
                            .pipe(takeUntil(ngUnsubscribe))
                            .subscribe(() => {
                                ngUnsubscribe.next();
                                ngUnsubscribe.complete();
                                this.router.navigateByUrl('/');
                            });
                    }
                });
        }
    }

}

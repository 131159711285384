import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {CoursesComponent} from './dashboard/containers/courses/courses.component';
import {MyCoursesComponent} from './dashboard/containers/my-courses/my-courses.component';
import {DashboardComponent} from './dashboard/dashboard.component';
import {LoginPageComponent} from './login-page/login-page.component';

const routes: Routes = [
    {path: 'login', component: LoginPageComponent},
    {
        path: '',
        component: DashboardComponent,
        children: [
            {
                path: '', component: MyCoursesComponent
            },
            {
                path: 'courses', component: CoursesComponent
            }
        ]
    }
];

@NgModule({
    imports: [RouterModule.forRoot(routes, {relativeLinkResolution: 'legacy'})],
    exports: [RouterModule]
})
export class AppRoutingModule {
}

import {Component, ComponentRef} from '@angular/core';
import {TrainingSimpleModel} from 'common-lib';
import {IModalDialog, IModalDialogButton, IModalDialogOptions} from 'ngx-modal-dialog';
import {Subject} from 'rxjs';

@Component({
    selector: 'ip-course-information-dialog',
    templateUrl: './course-information-dialog.component.html',
    styleUrls: ['./course-information-dialog.component.scss']
})
export class CourseInformationDialogComponent implements IModalDialog {
    actionButtons: IModalDialogButton[];
    course!: TrainingSimpleModel;

    constructor() {
        this.actionButtons = [
            {
                text: '',
                buttonClass: 'close-button',
                onAction: () => true
            }
        ];
    }

    dialogInit(
        reference: ComponentRef<IModalDialog>,
        options: Partial<IModalDialogOptions<CourseInformationDialogData>>
    ) {
        this.course = options.data!.course;
    }
}

export interface CourseInformationDialogData {
    course: TrainingSimpleModel;
}

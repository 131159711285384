import {DashboardStateModel} from './dashboard.model';
import {Injectable} from '@angular/core';
import {Selector, State} from '@ngxs/store';

@State<DashboardStateModel>({
    name: 'dashboard',
    defaults: {
        trainings: [],
        userTrainings: [],
        user: {}
    }
})
@Injectable()
export class DashbaordSelectors {
    @Selector()
    static userTrainings(state: DashboardStateModel) {
        console.log(0, state);
        return state.userTrainings;
    }

    @Selector()
    static trainings(state: DashboardStateModel) {
        return state.trainings;
    }
}

<div (click)="openCourseInformationDialog()" class="hide-on-iphone-se">
    <div class="course-item-container">
        <div class="background-container">
            <div class="d-flex align-items-center header-row">
                <ip-svg-pie
                    class="d-md-none difficulty-level"
                    [value]="courseData.difficultLevel"
                    textColor="#0267a0"
                ></ip-svg-pie>
                <i class="icon-small os-icon-small bi bi-windows d-md-none"></i>
                <div class="difficulty-level-small">{{ courseData.difficultLevel }}</div>
                <div style="flex-grow: 1"></div>
                <div class="like">
                    <span class="material-icons">favorite_border</span>
                </div>
            </div>
            <div class="d-flex flex-row align-items-center main-row">
                <div class="first-column">
                    <div class="d-flex flex-row"></div>
                    <div class="course-name align-self-start">{{ courseData.name }}</div>
                </div>
                <div style="flex-grow: 1"></div>
                <div class="second-column d-flex flex-row align-items-center">
                    <div style="flex-grow: 1"></div>
                    <div class="price-box" [ngClass]="{ promotion: courseData.pricing.hasPromotion }">
                        <div class="old-price" *ngIf="courseData.pricing.hasPromotion">
                            {{ courseData.pricing.basePrice | number: '1.2-2' }}&nbsp;zł
                        </div>
                        <div class="base-price">{{ courseData.pricing.price | number: '1.2-2' }}&nbsp;zł</div>
                    </div>
                    <i class="os-icon bi bi-windows"></i>
                    <ip-svg-pie
                        class="d-none d-md-block difficulty-level"
                        [value]="courseData.difficultLevel"
                        textColor="#0267a0"
                    ></ip-svg-pie>
                </div>
            </div>
        </div>
        <div *ngIf="courseData.pricing.hasPromotion" class="promotion-box"></div>
    </div>
    <button (click)="openAddCourseDialog($event)" class="add-course-button"></button>
</div>
<div class="show-on-iphone-se" [ngClass]="{ promotion: courseData.pricing.hasPromotion }">
    <div class="se-container">
        <div class="first-row d-flex flex-row align-items-center">
            <div class="difficulty-level">{{ courseData.difficultLevel }}</div>
            <i class="icon-small os-icon bi bi-windows d-md-none"></i>
            <div class="minimum-package">Pakiet</div>
            <div style="flex-grow: 1"></div>
            <i class="like-icon bi bi-heart"></i>
        </div>
        <div class="second-row">
            <div class="course-name">{{ courseData.name }}</div>
        </div>
        <div class="third-row d-flex justify-content-center align-items-center">
            <div class="price">{{ courseData.pricing.price | number: '1.2-2' }}&nbsp;zł</div>
            <div *ngIf="courseData.pricing.hasPromotion" class="base-price">
                {{ courseData.pricing.basePrice | number: '1.2-2' }}&nbsp;zł
            </div>
        </div>
    </div>
    <button (click)="openAddCourseDialog($event)" class="add-course-button"></button>
</div>

import {Component, OnInit} from '@angular/core';
import {Select, Store} from '@ngxs/store';
import {TrainingSimpleModel} from 'common-lib';
import {Observable} from 'rxjs';
import {GetTrainings} from '../../store/dashboard.actions';
import {DashbaordSelectors} from '../../store/dashboard.selectors';

@Component({
    selector: 'ip-courses',
    templateUrl: './courses.component.html',
    styleUrls: ['./courses.component.scss']
})
export class CoursesComponent implements OnInit {
    @Select(DashbaordSelectors.trainings) trainings$!: Observable<TrainingSimpleModel[]>;

    constructor(private store: Store) {
    }

    ngOnInit(): void {
        this.store.dispatch(new GetTrainings({count: 10, pageIndex: 0}));
    }
}

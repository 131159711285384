import {Component, ComponentRef} from '@angular/core';
import {TrainingSimpleModel} from 'common-lib';
import {IModalDialog, IModalDialogButton, IModalDialogOptions} from 'ngx-modal-dialog';
import {ToastrService} from 'ngx-toastr';

@Component({
    selector: 'ip-add-course-dialog',
    templateUrl: './add-course-dialog.component.html',
    styleUrls: ['./add-course-dialog.component.scss']
})
export class AddCourseDialogComponent implements IModalDialog {
    actionButtons: IModalDialogButton[];
    course!: TrainingSimpleModel;

    constructor(private toastr: ToastrService) {
        this.actionButtons = [
            {text: 'Anuluj'},
            {
                text: 'Tak dodaj',
                onAction: () => {
                    this.toastr.success('Gratulujemy wyboru!');
                    return true;
                }
            }
        ];
    }

    dialogInit(reference: ComponentRef<IModalDialog>, options: Partial<IModalDialogOptions<AddCourseDialogData>>) {
        this.course = options.data!.course;
    }
}

export interface AddCourseDialogData {
    course: TrainingSimpleModel;
}

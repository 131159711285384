import {AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Router} from '@angular/router';
import {AuthService, DomObserverService} from 'common-lib';

@Component({
    selector: 'ip-dashboard',
    templateUrl: './dashboard.component.html',
    styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit, AfterViewInit, OnDestroy {
    public title = 'Interactivity Platform';
    public menuVisible: boolean = false;

    @ViewChild('dropdownMenuLink') dropdownMenu: ElementRef | undefined;

    constructor(private router: Router, private auth: AuthService, private observer: DomObserverService) {

    }

    public ngOnInit(): void {
        // this is ow we can validate current session
        // additionally we should watch in interceptor any auth errors to logout immediately
        this.auth.isCurrentSessionValid().subscribe((isValid: boolean) => {
            if (!isValid) {
                this.onCloseAppClick();
            }
        });
    }

    public ngAfterViewInit(): void {
        // Code to ensure that we know account menu visibility correctly on all cases
        // we attach to attributes changes on menu, to detect changes on "aria-expanded" attribute, which gives
        // us 100% sure about menu visibility
        // BUGFIX: Solves a problem with left blue background and white space above mobile version of blue full screen menu
        this.observer.observeAttributes(this.dropdownMenu?.nativeElement)
            .subscribe(() => {
                this.menuVisible = this.dropdownMenu?.nativeElement.getAttribute('aria-expanded') == 'true';
            });
    }

    public ngOnDestroy(): void {
        this.observer.destroy();
    }

    public onMyAccountClick(): void {
        this.router.navigateByUrl('/');
    }

    public onCoursesClick(): void {
        this.router.navigateByUrl('/courses');
    }

    public onCloseAppClick(): void {
        this.auth.logout().subscribe(async () => {
            await this.router.navigateByUrl('/login');
        });
    }
}

<p class="fw-bolder">Czy dodać wybrany kurs:</p>
<div class="course-data d-flex align-items-center justify-content-end">
    <span class="course-name fw-bolder">{{ course.name }}</span>
    <i class="os-icon bi bi-windows"></i>
    <ip-svg-pie class="difficulty-level" [value]="course.difficultLevel" textColor="#0267a0"></ip-svg-pie>
</div>
<p class="fw-bolder">w cenie {{ course.pricing.price }} zł do swojego pakietu abonamentowego?</p>
<p>
    Kurs zostanie przypisany do Twojego konta i od razu będiesz mógł/mogła z niego skorzystać. Kwota
    {{ course.pricing.price }} zł zostanie doliczona jednorazowo do kolejnego rachunku.
</p>

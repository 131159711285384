<div class="h-100 main-container d-flex flex-column" [ngClass]="{ 'main-container-blue': menuVisible }">
    <nav class="navbar navbar-expand-md navbar-dark p-0">
        <a class="d-md-none main-menu-small" href=""><span class="material-icons x32">menu</span></a>
        <a class="navbar-brand d-none d-md-block" href="#">
            <img src="assets/images/navbar-logo.svg" width="180px" alt="Interactivity Platform logo"/>
        </a>
        <div class="logo-small logo-small__shadow d-md-none"></div>
        <div class="logo-small logo-small__shadow-cover d-md-none"></div>
        <img src="assets/images/logo-circle.svg" width="67px" alt="" class="logo-small logo-small__icon d-md-none"/>
        <div class="flex-grow-1"></div>
        <div class="dropdown">
            <a
                #dropdownMenuLink
                href="#"
                role="button"
                class="user-menu-trigger"
                id="dropdownMenuLink"
                data-bs-toggle="dropdown"
                aria-expanded="false"
            >
                <span class="material-icons x32">account_circle</span>
            </a>
            <div class="dropdown-menu user-menu" aria-labelledby="dropdownMenuLink">
                <a class="dropdown-item user-menu-item" href="#">Edytor</a>
                <a class="dropdown-item user-menu-item" (click)="onMyAccountClick()" href="#">Moje konto</a>
                <a class="dropdown-item user-menu-item" (click)="onCoursesClick()">Kursy</a>
                <a class="dropdown-item user-menu-item" (click)="onCloseAppClick()">Zakończ</a>
            </div>
        </div>

    </nav>
    <main class="container-fluid d-flex flex-row flex-grow-1 justify-content-center ms-auto me-auto mt-md-4">
        <router-outlet></router-outlet>
    </main>
</div>
<ip-dev-tools></ip-dev-tools>

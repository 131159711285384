import {Component, Input, OnInit} from '@angular/core';

@Component({
    selector: 'ip-svg-pie',
    templateUrl: './svg-pie.component.html',
    styleUrls: ['./svg-pie.component.scss']
})
export class SvgPieComponent implements OnInit {
    @Input() centerX = 0;
    @Input() centerY = 0;
    @Input() angle = Math.PI * 0.75;
    @Input() value = 0;
    @Input() textColor = 'red';
    attrD = 'M49,0 A50,50 0 0,1 49,99';

    constructor() {
    }

    ngOnInit(): void {
        const radius = 46;
        const bigArc = this.angle > Math.PI ? 1 : 0;
        const start = SvgPieComponent.polarToCartesian(50, 50, radius, 0);
        const end = SvgPieComponent.polarToCartesian(50, 50, radius, (this.value / 3) * Math.PI - 0.00001);

        this.attrD = `M${start.x},${start.y} A${radius},${radius} 0 ${bigArc},1 ${end.x},${end.y}`;
    }

    private static polarToCartesian(
        centerX: number,
        centerY: number,
        radius: number,
        angle: number
    ): { x: number; y: number } {
        angle -= Math.PI / 2;
        return {x: centerX + radius * Math.cos(angle), y: centerY + radius * Math.sin(angle)};
    }
}

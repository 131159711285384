import {Injectable} from '@angular/core';
import {Action, State, StateContext} from '@ngxs/store';
import {GetTrainings, GetUserTrainings} from './dashboard.actions';
import {DashboardStateModel} from './dashboard.model';
import {catchError, tap} from 'rxjs/operators';
import {TrainingsResponse, ApiTrainingsService} from 'common-lib';

@State<DashboardStateModel>({
    name: 'dashboard',
    defaults: {
        trainings: [],
        userTrainings: [],
        user: {}
    }
})
@Injectable()
export class DashboardReducers {
    constructor(private apiService: ApiTrainingsService) {
    }

    @Action(GetTrainings)
    getTrainings(
        {getState, patchState}: StateContext<DashboardStateModel>,
        {payload: {count, pageIndex}}: GetTrainings
    ) {
        return this.apiService.getTrainings(count, pageIndex).pipe(
            tap((response) => {
                patchState({trainings: response.records});
            }),
            catchError((error) => {
                return error;
            })
        );
    }

    @Action(GetUserTrainings)
    getUserTrainings(
        {getState, patchState}: StateContext<DashboardStateModel>,
        {payload: {count, pageIndex}}: GetTrainings
    ) {
        return this.apiService.getUserTrainings('', count, pageIndex).pipe(
            tap((response) => {
                patchState({userTrainings: response.records});
            })
        );
    }
}

import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {BrowserModule} from '@angular/platform-browser';
import {NgxsReduxDevtoolsPluginModule} from '@ngxs/devtools-plugin';
import {NgxsModule} from '@ngxs/store';
import {AppEnvironment, CommonLibConfig, CommonLibModule} from 'common-lib';
import {environment} from '../environments/environment';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {CoursesComponent} from './dashboard/containers/courses/courses.component';
import {MyCoursesComponent} from './dashboard/containers/my-courses/my-courses.component';
import {DashboardComponent} from './dashboard/dashboard.component';
import {LoginPageComponent} from './login-page/login-page.component';
import {SvgPieComponent} from './svg-pie/svg-pie.component';
import {DashboardReducers} from './dashboard/store/dashboard.reducers';
import {CourseItemComponent} from './dashboard/course-item/course-item.component';
import {ModalDialogModule} from 'ngx-modal-dialog';
import {AddCourseDialogComponent} from './dashboard/course-item/add-course-dialog/add-course-dialog.component';
import {ToastNoAnimationModule} from 'ngx-toastr';
import {CourseInformationDialogComponent} from './dashboard/course-item/course-information-dialog/course-information-dialog.component';

@NgModule({
    declarations: [
        AppComponent,
        DashboardComponent,
        LoginPageComponent,
        CoursesComponent,
        MyCoursesComponent,
        SvgPieComponent,
        CourseItemComponent,
        CourseInformationDialogComponent,
        AddCourseDialogComponent
    ],
    imports: [
        BrowserModule,
        ReactiveFormsModule,
        FormsModule,
        AppRoutingModule,
        CommonLibModule.forRoot(
            new CommonLibConfig({
                apiUrl: environment.apiUrl,
                filesUrl: environment.filesUrl,
                environment: environment.production ? AppEnvironment.Production : AppEnvironment.Test
            })
        ),
        NgxsModule.forRoot([DashboardReducers], {developmentMode: !environment.production}),
        NgxsReduxDevtoolsPluginModule.forRoot(),
        ModalDialogModule.forRoot(),
        ToastNoAnimationModule.forRoot({positionClass: 'toast-bottom-center', disableTimeOut: true})
    ],
    providers: [],
    bootstrap: [AppComponent]
})
export class AppModule {
}

<nav aria-label="breadcrumb">
    <ol class="breadcrumb">
        <li class="breadcrumb-item"><a href="#">COGNUS</a></li>
        <li class="breadcrumb-item active" aria-current="page">Kursy</li>
    </ol>
</nav>

<div class="rw-1 rh-4 rw-lg-12" style="background-color: red"></div>


<p class="title hide-on-iphone-se">Kursy</p>
<div class="first-row d-flex flex-sm-row flex-column-reverse">
    <div class="d-flex">
        <img
            class="os-icon os-icon-windows"
            src="assets/images/windows-course-icon.svg"
            width="28px"
            alt="linux course icon"
        />
        <img
            class="os-icon os-icon-linux"
            src="assets/images/linux-course-icon.svg"
            width="24px"
            alt="linux course icon"
        />
        <div class="d-sm-none" style="flex-grow: 1"></div>
        <a class="d-sm-none" href="">Więcej opcji wyszukiwania</a>
    </div>
    <div class="search-box d-flex flex-row">
        <input class="search-input" style="flex-grow: 1" placeholder="Szukaj kursu" type="text"/>
        <i class="search-icon bi bi-search"></i>
    </div>
</div>
<div class="second-row d-sm-flex d-none flex-row justify-content-end">
    <a href="">Więcej opcji wyszukiwania</a>
</div>
<div class="courses-list">
    <ip-course-item
        class="course-item"
        [courseData]="trainingItem"
        *ngFor="let trainingItem of trainings$ | async"
    ></ip-course-item>
</div>

import {TrainingSimpleModel} from 'common-lib';
import {ChangeDetectionStrategy, Component, Input, OnInit, ViewContainerRef} from '@angular/core';
import {ModalDialogService} from 'ngx-modal-dialog';
import {AddCourseDialogComponent, AddCourseDialogData} from './add-course-dialog/add-course-dialog.component';
import {
    CourseInformationDialogComponent,
    CourseInformationDialogData
} from './course-information-dialog/course-information-dialog.component';

@Component({
    selector: 'ip-course-item',
    templateUrl: './course-item.component.html',
    styleUrls: ['./course-item.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class CourseItemComponent implements OnInit {
    @Input() courseData!: TrainingSimpleModel;
    @Input() myCourse!: boolean;

    constructor(private modalService: ModalDialogService, private viewRef: ViewContainerRef) {
    }

    ngOnInit(): void {
    }

    openAddCourseDialog($event: MouseEvent): void {
        $event.stopPropagation();
        this.modalService.openDialog(this.viewRef, {
            title: 'Dodawanie nowego kursu',
            childComponent: AddCourseDialogComponent,
            data: {
                course: this.courseData
            } as AddCourseDialogData
        });
    }

    openCourseInformationDialog(): void {
        this.modalService.openDialog(this.viewRef, {
            title: 'Informacje o kursie',
            childComponent: CourseInformationDialogComponent,
            settings: {},
            data: {
                course: this.courseData
            } as CourseInformationDialogData
        });
    }
}

export class GetTrainings {
    static readonly type = '[DASHBOARD] Get trainigns';

    constructor(public payload: { count: number; pageIndex: number }) {
    }
}

export class GetUserTrainings {
    static readonly type = '[DASHBOARD] Get user trainigns';

    constructor(public payload: { count: number; pageIndex: number }) {
    }
}

<p class="course-name">{{ course.name }}</p>
<div class="details-table">
    <div class="row">
        <div class="col-3">Cena (jednorazowa):</div>
        <div class="col-9 pricing">
            <span *ngIf="course.pricing.hasPromotion" class="base-price">{{ course.pricing.basePrice }} zł</span>
            <span class="price">&nbsp;{{ course.pricing.price }} zł</span>
            &nbsp;
            <i class="bi bi-info-circle-fill icon-info"></i>
        </div>
    </div>
    <div class="row">
        <div class="col-3">System operacyjny:</div>
        <div class="col-3">
            {{ course.os }}
            <i class="bi bi-info-circle-fill icon-info"></i>
        </div>
        <div class="col-3">Poziom trudności:</div>
        <div class="col-3">
            {{ course.difficultLevel }}/10&nbsp;
            <i class="bi bi-info-circle-fill icon-info"></i>
        </div>
    </div>
    <div class="row">
        <div class="col-3">Całkowita liczba kroków:</div>
        <div class="col-3"></div>
        <div class="col-3">Ocena użytkowników:</div>
        <div class="col-3"></div>
    </div>
    <div class="row">
        <div class="col-3">Opis kursu:</div>
        <div class="col-9 course-description">{{ course.description }}</div>
    </div>
    <div class="row">
        <div class="col-3">Agenda</div>
        <div class="col-9"></div>
    </div>
</div>
